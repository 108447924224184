<template>
  <div class="handleBody" v-loading="loadData">
    <!-- 标题 -->
    <div class="title">
      <!-- 查重合并进度：共 -->
      {{ $t("label.mr.descreption.oneGroupReData1") }}
      {{ totalCount }}
      <!-- 组数据 -->
      {{ $t("label.mr.descreption.reDataList5") }}
      <!-- ，已处理{{ handleCount }}组 -->
    </div>
    <!-- 下面数据 处理完了 -->
    <div class="content" v-show="isHandled">
      <!-- 按钮组 -->
      <div>
        <button @click="goBack" style="color: #1b5297">
          <!-- 返回,查看重复数据列表 -->
          {{ $t("label.mr.backLookReData") }}
        </button>
        <button style="color: #ffffff; background: #006dcc">
          <!-- 返回列表 -->
          {{ $t("label.returntolist") }}
        </button>
      </div>
      <!-- 内容区域 -->
      <div style="text-align: center">
        <div class="imgStyle">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-empty-search"></use>
          </svg>
        </div>
        <div style="margin-top: 20px; font-size: 16px; color: #333333">
          <!-- 已处理完全部数据 -->
          {{ $t("vue_label_checkduplicate_all_processed") }}
        </div>
      </div>
    </div>
    <!-- 下面区域 没处理完-->
    <div class="content" v-show="!isHandled">
      <!-- 按钮组 -->
      <div>
        <button @click="goBack" style="color: #1b5297">
          <!-- 返回，查看重复数据列表 -->
          {{ $t("label.mr.backLookReData") }}
        </button>
        <button style="color: #1b5297" @click="openCombin" v-show="!isHandled">
          <!-- 加载选中的信息 -->
          {{ $t("label.mr.loadReDate") }}
        </button>
        <button
          @click="openNext"
          style="color: #ffffff; background: #006dcc; border: none"
        >
          <!-- 跳过该组，前往下一组 -->
          {{ $t("label.mr.nextGroup") }}
        </button>
      </div>
      <!-- 提示信息 -->
      <div class="info">
        <!-- 请至少勾选两条数据，点击【加载选中的信息】，进入数据合并页面。当前是第 -->
        {{ $t("label.mr.descreption.oneGroupNote1") }}
        {{ index }}
        <!-- 组数据 -->
        {{ $t("label.mr.descreption.reDataList5") }}
      </div>
      <!-- 数据展示区域 -->
      <div>
        <table>
          <!-- 列信息 -->
          <tr>
            <td
              v-for="(Sitem, Sindex) in resultObj.selectedFieldList"
              :key="Sindex"
            >
              <input
                @change="choseAll($event)"
                ref="choseAll"
                v-if="Sitem.schemefieldName == 'name'"
                type="checkbox"
                style="margin-right: 5px"
                checked
              />
              {{ Sitem.labelName }}
            </td>
          </tr>
          <!-- 数据区域 -->
          <tr
            v-for="(Titem, Tindex) in oneGroupDataList"
            :key="Tindex"
            style="height: 40px"
          >
            <td
              v-for="(Fitem, Findex) in Titem"
              v-show="Findex !== 'id'"
              :key="Findex"
            >
              <input
                v-if="Findex == resultObj.selectedFieldList[0].schemefieldName"
                type="checkbox"
                ref="choseSingle"
                @change="choseSingle"
                :data-repeatId="Titem.id"
                style="margin-right: 5px"
                checked
              />
              {{ Fitem }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getOneGroupData } from "./api.js";
export default {
  name: "merge",
  data() {
    return {
      //保存查重数据一共的条数
      totalCount: this.$route.query.groupsnum,
      //已处理数据条数
      handleCount: 0,
      //是否处理完重复数据
      isHandled: false,
      obj: sessionStorage.getItem("cnkiData"),
      resultObj: {},
      newObject: {},
      index: this.$route.query.index,
      loadData: false,
      oneGroupDataList: [],
    };
  },
  created() {},
  mounted() {
    if (this.totalCount == 0) {
      this.isHandled = true;
    }
    this.loadData = true;
    let object = JSON.parse(this.obj);
    this.oneGroupData(object);
    // let object = JSON.parse(this.obj.object);
    let newObject = {};
    for (var key in object) {
      newObject[key] = object[key];
    }
    this.newObject = newObject;
  },
  methods: {
    openNext() {
      // 获取当前的组数
      let num = this.index;
      if (num < this.totalCount) {
        num = ++this.index;
        this.newObject.curGroup = num;
        this.loadData = true;
        this.oneGroupData(this.newObject);
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.weixin.nomore"),
        });
      }
    },
    openCombin() {
      let object = JSON.parse(this.obj);
      // 先获取选中的所有的重复数据
      let arr = [];
      arr = this.$refs.choseSingle;
      arr = arr.filter((item) => {
        return item.checked == true;
      });
      if (arr.length >= 2) {
        let idsArray = [];
        arr.map((item) => {
          idsArray.push(item.dataset.repeatid);
        });
        let str = "";
        let ids = "";
        idsArray.map((item) => {
          str = item + ",";
          ids += str;
        });
        ids = ids.replace(/,$/, "");
        ids = ids.replace(/$/, "'");
        ids = ids.replace(/^/, "'");
        ids = ids.replace(/,/g, "','");
        object.ids = ids;
        object = JSON.stringify(object);
        sessionStorage.setItem("cnkiParameter", object);
        this.$router.push({
          path: `/commonObjects/combinationRepeat`,
          query: {
            objlabel: this.resultObj.objLabel,
          },
        });
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.mr.selectTwoData"),
        });
      }
    },
    choseSingle() {
      // 获取所有的单个
      let arr = [];
      arr = this.$refs.choseSingle;
      let count = 0;
      arr.map((item) => {
        if (item.checked == true) {
          count++;
        }
      });

      if (count == this.resultObj.reDataOneGroup.length) {
        this.$refs.choseAll[0].checked = true;
      } else {
        this.$refs.choseAll[0].checked = false;
      }
    },
    choseAll(event) {
      // 获取当前选中元素
      // 获取所有的单个
      let arr = [];
      arr = this.$refs.choseSingle;
      arr.map((item) => {
        if (event.srcElement.checked == true) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    },
    async oneGroupData(index) {
      let result = await getOneGroupData(index);
      this.loadData = false;
      this.resultObj = result.data;
      this.resultObj.selectedFieldList.map((item) => {
        if (item.schemefieldType == "Y" || item.schemefieldType == "R") {
          item.schemefieldName += "ccname";
        }
      });
      this.oneGroupDataList = [];
      this.resultObj.reDataOneGroup.map((item) => {
        let dataObject = {};
        // 遍历字段信息  如果有则为对象添加数据
        this.resultObj.selectedFieldList.map((Titem) => {
          // if (item[Titem.schemefieldName]) {
          dataObject[Titem.schemefieldName] = item[Titem.schemefieldName];
          // }
          dataObject.id = item.id;
        });
        this.oneGroupDataList.push(dataObject);
      });
      this.loadData = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.handleBody {
  margin: 10px;
  background: #ffffff;
  border-radius: 4px;
}
button {
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  margin-right: 10px;
  outline: none;
  font-size: 14px;
  padding: 3px 8px;
  margin-right: 15px;
}
.title {
  height: 52px;
  padding-left: 10px;
  line-height: 52px;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  background: #f3f2f2;
}
.content {
  padding: 20px;
}
.info {
  margin: 15px 0px;
  font-size: 14px;
  color: #333333;
}
table {
  width: 100%;
}
td {
  min-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dataArea td:first-child {
  color: #006dcc;
}
.imgStyle {
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .icon {
  width: 420px;
  height: 232px;
}
</style>
